import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "../../services/api-service";
import { isRazorPay, numberWithCommas } from "../../services/Utility";
import iconThumsup from "../../assets/thumsup.svg";
import coinImg from "../../assets/coin.svg";
import logoCashE from "../../assets/logo_CashE.png";
import logoMoneywideWhite from "../../assets/logo_Moneywide_white.png";
import logoMoneywide from "../../assets/logo_Moneywide.png";
import logoKreditbee from "../../assets/logo_Kreditbee.png";
import logoKreditbeeWhite from "../../assets/logo_Kreditbee_white.png";
import logoFibe from "../../assets/logo_fibe.png";
import logoFibeWhite from "../../assets/logo_fibe_white.png";
import "./Offer.scss";
import { off } from "process";

const { Title, Text, Link } = Typography;

interface IOfferMultilenderV2 {
  extraData: any;
  componentParameters: any;
  submitTaskV2: any;
  loanType?: any;
  complementorUserId?: any;
}

const OfferMultilenderV2: React.FunctionComponent<IOfferMultilenderV2> = (
  props: IOfferMultilenderV2
) => {
  const {
    extraData,
    componentParameters,
    submitTaskV2,
    loanType,
    complementorUserId,
  } = props;

  const [offerPayload, setOfferPayload] = useState({} as any);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getJourneyOffer();
  }, []);

  const renderLenderImage = (lenderName: any) => {
    if (lenderName?.toLowerCase().includes("cashe")) {
      return logoCashE;
    } else if (lenderName?.toLowerCase().includes("moneywide")) {
      return isRazorPay(complementorUserId)
        ? logoMoneywideWhite
        : logoMoneywide;
    } else if (lenderName?.toLowerCase().includes("kreditbee")) {
      return isRazorPay(complementorUserId)
        ? logoKreditbeeWhite
        : logoKreditbee;
    } else if (lenderName?.toLowerCase().includes("fibe")) {
      return isRazorPay(complementorUserId) ? logoFibeWhite : logoFibe;
    }
    return "";
  };

  const getJourneyOffer = () => {
    const lenders = extraData?.processVariables?.lenderOfferDetails?.lenders.map((lender: any) => lender.lenderId)?.join(", ") ?? "";
    const params = {
      caseId: extraData?.processVariables?.dsaCaseId,
      offerLimit: (componentParameters?.viewOffersDescending == "top" && componentParameters?.number > 0) ? componentParameters?.number : undefined,
      lenderIds: componentParameters?.displayLenderLoginOffers ? lenders : "",
      showBreOfferOnlyWhenLenderOfferSanctioned: componentParameters?.showBreOfferOnlyWhenLenderOfferSanctioned,
    };
    setLoading(true);
    API_SERVICE.getJourneyOffer(params)
      .then(({ data }) => setOfferPayload(data?.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const renderMultiOfferForIncred = () => {
    return (
      <>
        <div className="offerMainTable mt-1">
          <Row>
            <Col span={7}>Loan Amount</Col>
            <Col span={7}>Processing Fee</Col>
            <Col span={3}>ROI</Col>
            <Col span={6}>Tenure</Col>
          </Row>
          <div className="offerScreenDetails">
            {offerPayload?.lenderOfferDetails?.map((items) => {
              return (
                <Row>
                  <Col span={7}>
                    Rs. {numberWithCommas(items?.calculatedLoanAmount)}
                  </Col>
                  <Col span={7}>{items?.processingFee}%</Col>
                  <Col span={3}>{items?.interest}%</Col>
                  <Col span={6}>{items?.tenureInYears} Months</Col>
                </Row>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderMultiOffer = () => {
    const lender = offerPayload?.lenderOfferDetails?.at(0);
    return (
      <>
        <div className="offerScreen" style={{ marginTop: "20px" }}>
          <img src={coinImg} style={{ height: "100px", width: "100px" }} />
          <div className="remove-border-loan-eligiblity-title">
            {((loanType == "PERSONAL_LOAN_ONLY" &&
              (offerPayload?.lenderOfferDetails?.at(0)?.calculatedLoanAmount ?? 0) > 0) ||
              (loanType != "PERSONAL_LOAN_ONLY" &&
                offerPayload?.maxAllowedLoanAmount > 0)) && (
              <div className="offer-text" style={{ color: "black" }}>
                You're eligible for a loan upto{" "}
                <span className="offer-amount">
                  &nbsp;₹{" "}
                  {numberWithCommas(
                    Math.round(
                      loanType == "PERSONAL_LOAN_ONLY"
                        ? offerPayload?.lenderOfferDetails?.at(0)
                            ?.calculatedLoanAmount
                        : offerPayload?.maxAllowedLoanAmount
                    )
                  )}
                </span>
              </div>
            )}
          </div>
          {loanType == "PERSONAL_LOAN_ONLY" &&
          (offerPayload?.lenderOfferDetails?.length ?? 0) > 0 ? (
            <div style={{width: "50%"}}>{renderMultiOfferPl()}</div>
          ) : (
            ""
          )}

          {loanType != "PERSONAL_LOAN_ONLY" ? (
            <div className="offerSubHeader">
              {offerPayload?.offerDetails?.length == 1
                ? `Top ${offerPayload?.offerDetails?.length} offer/s for you`
                : "Top offer/s for you"}
            </div>
          ) : (
            <div className="offerSubHeader">
              {(offerPayload?.offerDetails?.length ?? 0) > 0 &&
                (offerPayload?.lenderOfferDetails?.length ?? 0) == 0 && (
                  <span>Offer/s from Top lender/s</span>
                )}
            </div>
          )}

          {(offerPayload?.offerDetails?.length ?? 0) > 0 && (
            <div className="offerMainTable mt-1">
              <Row>
                <Col span={7}>Loan Amount</Col>
                <Col span={8}>EMI</Col>
                <Col span={3}>ROI</Col>
                <Col span={6}>Tenure</Col>
              </Row>
              <div className="offerScreenDetails">
                {offerPayload?.offerDetails?.map((items) => {
                  return (
                    <Row>
                      <Col span={7}>
                        Rs. {numberWithCommas(items?.calculatedLoanAmount)}
                      </Col>
                      <Col span={8}>Rs. {numberWithCommas(items?.totalEMI)}</Col>
                      <Col span={3}>{items?.interest}%</Col>
                      <Col span={6}>{items?.tenureInYears} Years</Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          )}

          <Button
            type="primary"
            size="large"
            style={{ width: "auto", marginBlock: "10px" }}
            onClick={() => submitTaskV2()}
          >
            View Application Details
          </Button>
        </div>
      </>
    );
  };

  const renderMultiOfferPl = () => {
    const lender = offerPayload?.lenderOfferDetails?.at(0);
    return (
      <>
      {!lender.lenderName.toLowerCase().includes("incred") &&
        <div>
          <span className="offer-lender-msg">
            {(lender?.calculatedLoanAmount ?? 0) == 0 && (
              <Text>You are almost done, </Text>
            )}
            &nbsp;
            <Link target="_blank" href={lender?.redirectUrl}>
              Click here
            </Link>
            &nbsp;to complete your application.{" "}
          </span>
        </div>
         }
        
        <div className="offer-lender-msg">

        {!lender.lenderName.toLowerCase().includes("incred") ? (
          <>
            <Text style={{ marginTop: "71px", marginRight: "10px" }} className="">
              Offered By
            </Text>
            <img
              src={renderLenderImage(lender?.lenderName)}
              alt=""
              style={{
                height: "110px",
                width: "110px",
                objectFit: "contain",
              }}
            />
          </>
        ) : (
          renderMultiOfferForIncred()
        )}
        </div>

        {lender.lenderName.toLowerCase().includes("incred") &&
            <div style={{padding: "20px"}}>
            <span className="offer-lender-msg">
              {(offerPayload?.lenderOfferDetails?.calculatedLoanAmount ?? 0) == 0 && (
                <Text>You are almost done, </Text>
              )}
              &nbsp;
              <Link target="_blank" href={lender.redirectUrl}>
                Click here
              </Link>
              &nbsp;to complete your application.{" "}
            </span>

            <Text style={{ marginTop: "71px", marginRight: "10px" }}>
              <h5 style={{textAlign: "center",marginTop: "3%"}}>  <span style={{fontSize:"13px", fontWeight: "normal"}}>Offered By</span>  {lender.lenderName}</h5>
            </Text>

          </div>
          }

        {(offerPayload?.offerDetails?.length ?? 0) > 0 &&
          (offerPayload?.lenderOfferDetails?.length ?? 0) > 0 && (
            <>
              <Divider
                style={{
                  border: "0.6px solid rgba(73, 146, 200, 1)",
                  marginTop: "5px",
                }}
              />
              <span className="offer-lender-msg" style={{ marginTop: "13px" }}>
                Other offer/s from Top lender/s
              </span>
            </>
          )}
      </>
    );
  };

  const renderMultiOfferThanlyou = () => {
    return (
      <>
        <div className="thankyou-center-view">
          <Space className="main-processing">
            <img src={iconThumsup} />
            <Title level={4} style={{ color: "black", fontWeight: "normal", paddingBlock:'30px' }}>
              {componentParameters?.thankyouMessage ??
                "We are processing your loan request and will be in touch shortly!"}
            </Title>
            <Button
              type="default"
              size="large"
              className="close-btn"
              onClick={() => submitTaskV2()}
            >
              Close
            </Button>
          </Space>
        </div>
      </>
    );
  };

  if (loading) {
    return (
      <div className={"loader-view"}>
        <Spin tip="We are processing your request, please wait" size="large" />
      </div>
    );
  }

  return (
    <>
      {(offerPayload?.offerDetails == null || (offerPayload?.offerDetails?.length ?? 0) == 0) && offerPayload?.lenderOfferDetails == null
        ? renderMultiOfferThanlyou()
        : renderMultiOffer()}
    </>
  );
};

export default OfferMultilenderV2;
